<nav class="navbar navbar-style-two navbar-expand-lg navbar-light sticky-top">
    <div class="container-fluid">
        <div class="logo">
            <a routerLink="/home-nine">
                <img src="assets/img/logo.png" id="header-logo" class="black-logo" alt="Logo">
            </a>
        </div>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a href="/" class="nav-link">
                        Home
                    </a>
                </li>
                <li class="nav-item">
                    <a href="/#products" class="nav-link">
                        Products
                    </a>
                </li>
                <li class="nav-item">
                    <a href="/#about" class="nav-link">
                        About us
                    </a>
                </li>
                <li class="nav-item">
                    <a href="/#apply" class="nav-link">
                        Parnetship
                    </a>
                </li>
                <li class="nav-item">
                    <a href="/carnaval" class="nav-link">
                        Carnaval
                    </a>
                </li>
                <li class="nav-item">
                    <a href="/download" class="nav-link">
                        Download App
                    </a>
                </li>
            </ul>

            <div class="others-option">
                <div class="d-flex align-items-center">
                    <div class="option-item">
                        <a href="https://forms.gle/MVamXvFK55eeJKvp6" class="sign-up">Become a Partner</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</nav>

<div id="home" class="better-home-area">
    <div class="container-fluid">
        <ul class="social-links">
            <li><a href="https://www.linkedin.com/company/nagringaapp"><i class='bx bxl-linkedin'></i></a></li>
            <li><a href="https://www.instagram.com/nagringa.app"><i class='bx bxl-instagram'></i></a></li>
        </ul>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="better-home-content">
                    <span class="sub-title">Welcome to NaGringa</span>
                    <h1>Meaningful Connections, Elevated!</h1>
                    <p>Revolutionize Your Business Engagement: Tailored for Events, Loyalty Programs, and Amplifying QR
                        Code Scans for Unprecedented Audience Interaction!</p>

                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="better-home-image">
                    <img src="assets/img/home-banner.png" alt="image">

                </div>
            </div>
        </div>
    </div>
</div>

<div class="information-area pt-100 pb-70">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-3 col-sm-6">
                <div class="information-card">
                    <div class="icon">
                        <i class='bx bxs-id-card'></i>
                    </div>
                    <p>
                        <label>Loyalty Programs</label>
                        <span>Interactive and Fun!</span>
                    </p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="information-card">
                    <div class="icon">
                        <i class='bx bx-network-chart'></i>
                    </div>
                    <p>
                        <label>Networking Event</label>
                        <span>Meaningful Connections</span>
                    </p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="information-card">
                    <div class="icon">
                        <i class='bx bx-party'></i>
                    </div>
                    <p>
                        <label>Parties</label>
                        <span>The Digital Experience</span>
                    </p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="information-card">
                    <div class="icon">
                        <i class='bx bx-qr'></i>
                    </div>
                    <p>
                        <label>QR Codes</label>
                        <span>Attract more scans</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="about" class="about-style-area pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-style-image-wrap">
                    <img src="assets/img/about.png" height="500" alt="image">

                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-style-wrap-content">
                    <span class="sub-title">About NaGringa</span>
                    <h3>We Are Committed to Fostering the Organic Growth of your Business</h3>
                    <p class="bold">A cutting-edge platform designed to provide your customers with an exceptional
                        experience, boosting their engagement and elevating your brand awareness.</p>

                    <div class="about-list-tab">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" id="about-1-tab" data-bs-toggle="tab" href="#about-1"
                                    role="tab" aria-controls="about-1">Our Mission</a>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link" id="about-2-tab" data-bs-toggle="tab" href="#about-2" role="tab"
                                    aria-controls="about-2">Our Vision </a>
                            </li>
                        </ul>

                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active" id="about-1" role="tabpanel">
                                <div class="content">
                                    <p>At NaGringa, our mission is to revolutionize the business landscape by offering
                                        an innovative platform that not only enhances customer experiences but also
                                        amplifies brand engagement and awareness.</p>

                                    <ul class="list">
                                        <li><i class='bx bx-chevrons-right'></i> Elevated Customer Experiences</li>
                                        <li><i class='bx bx-chevrons-right'></i> Enhanced Brand Engagement</li>
                                        <li><i class='bx bx-chevrons-right'></i> Amplified Brand Awareness</li>
                                    </ul>
                                </div>
                            </div>

                            <div class="tab-pane fade" id="about-2" role="tabpanel">
                                <div class="content">
                                    <p>At NaGringa, we envision a future where businesses seamlessly connect with their
                                        audience, fostering genuine relationships and leaving an indelible mark on the
                                        global market.</p>

                                    <ul class="list">
                                        <li><i class='bx bx-chevrons-right'></i> Seamless Connectivity</li>
                                        <li><i class='bx bx-chevrons-right'></i> Human-Centric Interaction</li>
                                        <li><i class='bx bx-chevrons-right'></i> Global Impact, Local Significance</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="about-style-shape-1">
        <img src="assets/img/more-home/about/about-shape.png" alt="image">
    </div>
</div>

<div id="products" class="faq-style-area-with-full-width pb-100">
    <div class="container">
        <div class="row align-items-center">

            <div class="col-lg-6 col-md-12">
                <div class="faq-style-accordion">
                    <span class="sub-title">OUR PRODUCTS</span>
                    <h3>Bridging Businesses and Customers</h3>
                    <p>Leveraging innovative solutions, we facilitate seamless interaction between your business and its
                        customers, empowering them to promote your business organically, fostering sustainable growth.
                    </p>

                    <div class="accordion" id="FaqAccordion">
                        <div class="accordion-item">

                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <div class="icon">
                                    <i class='bx bxs-id-card'></i>
                                </div>
                                Loyalty Programs
                            </button>
                            <div id="collapseOne" class="accordion-collapse collapse show"
                                data-bs-parent="#FaqAccordion">
                                <div class="accordion-body">
                                    <p>
                                        Foster a frictionless digital journey for customers through a user-friendly
                                        check-in process, complemented by an effective method for your staff to validate
                                        their purchases.
                                    </p>
                                    <br />
                                    <p>
                                        <b>Stamp Cards</b> with the flexibility to tailor multiple cards for specific
                                        products.
                                    </p>
                                    <br />
                                    <p>
                                        <b>Cashback Card</b> rewarding customers based on their consumption, with the
                                        optional ability to customize cashback percentages for each product.
                                    </p>
                                    <br />
                                    <p>
                                        <b>Digital Scratcher</b> increases the engagement of your audience and also it
                                        motivates them to promote your Business organically.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                <div class="icon">
                                    <i class='bx bxs-network-chart'></i>
                                </div>
                                Events
                            </button>
                            <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#FaqAccordion">
                                <div class="accordion-body">
                                    <p>The digital experience adapts depending on the type of event you are hosting.</p>
                                    <br />
                                    <p>For <b>Networking Events</b> our tool is designed to assist attendees in
                                        establishing meaningful connections both prior to, during, and/or subsequent to
                                        the events.</p>
                                    <br />
                                    <p>For <b>Ticketed Events</b>, we designed an effective mechanism to incentivize the
                                        audience to acquire their tickets in advance. This approach not only augments
                                        the promotional endeavors for the event but also facilitates early engagement
                                        with the audience.</p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                <div class="icon">
                                    <i class="material-symbols-outlined">
                                        local_activity
                                    </i>
                                </div>
                                Offers
                            </button>
                            <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#FaqAccordion">
                                <div class="accordion-body">
                                    <p>
                                        The platform empowers your business to showcase various offers, engaging and
                                        attracting a broader customer base.
                                    </p>
                                    <br>
                                    <p>
                                        <b>Limited-time Offers</b> allow your business to swiftly promote special deals
                                        to customers.
                                    </p>
                                    <br>
                                    <p>
                                        <b>Instant Offer</b> provide customers with the opportunity to win prizes upon
                                        checking in at your venue.
                                    </p>
                                    <br>
                                    <p>
                                        <b>Fixed Offers</b> promote standardized deals to all users on the platform.
                                    </p>
                                    <br>
                                    <p>
                                        <b>Scratcher Offers</b> involve your business in a public scratcher, expanding
                                        reach and attracting new customers.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                <div class="icon">
                                    <i class="material-symbols-outlined">
                                        qr_code
                                    </i>
                                </div>
                                Interactive QR Codes
                            </button>
                            <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#FaqAccordion">
                                <div class="accordion-body">
                                    <p>
                                        Our strategic approach is dedicated to assisting your business in boosting QR
                                        code scans. Through our provided tools, we aim to elevate audience engagement
                                        and motivate them to actively promote your business, fostering organic growth.
                                    </p>
                                    <p>
                                        Get in touch to learn more.
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="faq-style-image">
                    <img src="assets/img/products.png" width="500" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>

<div id="apply" class="mortgage-quote-area-with-full-width">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-lg-6 col-md-6 p-0">
                <div class="mortgage-quote-content">
                    <h3>Become a Partner</h3>
                    <p>Strategic Solutions for Sustainable Growth: Upon a comprehensive understanding of your business
                        requirements, we identify the right tool to drive and support the engagement with your customers
                    </p>
                    <a href="https://forms.gle/MVamXvFK55eeJKvp6" class="quote-btn">Apply Here</a>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 p-0">
                <div class="mortgage-quote-image"></div>
            </div>
        </div>
    </div>
</div>

<footer class="footer-style-area pt-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-sm-6 col-lg-3">
                <div class="single-footer-widget">
                    <div class="footer-logo">
                        <a href="#">
                            <img src="assets/img/logo-white.png" id="footer-logo" alt="Logo">
                        </a>
                        <p>Meaningful Connections, Elevated: Unleashing the Power of Our Interactive Business Platform!
                        </p>
                        <ul class="footer-social">
                            <li><a href="https://www.linkedin.com/company/nagringaapp"><i
                                        class='bx bxl-linkedin'></i></a></li>
                            <li><a href="https://www.instagram.com/nagringa.app"><i class='bx bxl-instagram'></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-2">
                <div class="single-footer-widget ps-3">
                    <h3>Quick Links</h3>

                    <ul class="quick-link">
                        <li>
                            <a href="/#products">Products</a>
                        </li>
                        <li>
                            <a href="/#about">About Us</a>
                        </li>
                        <li>
                            <a href="/#apply">Partnership</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 col-lg-2">
                <div class="single-footer-widget ps-3">
                    <h3>Special Events</h3>

                    <ul class="quick-link">
                        <li>
                            <a href="/carnaval">Carnaval</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 col-lg-2">
                <div class="single-footer-widget ps-3">
                    <h3>Support</h3>

                    <ul class="quick-link">
                        <li>
                            <a href="mailto:contact@nagringa.app">Contact</a>
                        </li>
                        <li>
                            <a href="/legal/privacy-policy">Privacy Policy</a>
                        </li>
                        <li>
                            <a href="/legal/ts-and-cs">Terms & Conditions</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="single-footer-widget">
                    <h3>Address</h3>

                    <ul class="address-info">
                        <li>
                            <i class='bx bxl-whatsapp'></i>
                            <a href="tel:+61411190506">0411 190 506</a>
                        </li>
                        <li>
                            <i class='bx bx-message-square-detail'></i>
                            <a href="mailto:contact@nagringa.app">contact@nagringa.app</a>
                        </li>
                        <li>
                            <i class='bx bx-current-location'></i>
                            Brisbane, QLD Austrália
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="copyright-style-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="copyright-item">
                        <p>© NaGringa PTY LTD</p>
                    </div>
                </div>

            </div>
        </div>
    </div>
</footer>
