<header>
  <div class="tld">
    <img src="/assets/images/tld-logo-full.png" />
  </div>
  <h2>in partnership with</h2>
  <div class="ng">
    <img src="/assets/img/logo.png" />
  </div>
</header>
<div class="flex-1"></div>
<h1>
  Connecting<br/>
  International Communities<br/>
  in Australia
</h1>
<div class="flex-1"></div>

<a href="https://events.humanitix.com/june-masterclass-empower-your-finances-plus-introduction-to-nagringa" target="_blank" class="masterclass">
  Join Next Masterclass
  <label>11th Jun Online (7pm - 8:30pm)</label>
</a>
<a href="https://forms.gle/PYmafqLnbbxUZzWq7" target="_blank" class="create">
  Add your Business
</a>
<a [href]="url" target="_blank" class="download">
  Download the App
</a>

