
<div class=WordSection1>

<p class=MsoNormal style='line-height:7.95pt'><a name=page1></a><span
style='font-size:12.0pt'>&nbsp;</span></p>

<p class=MsoNormal align=center style='margin-right:.5pt;text-align:center'><b><span
style='font-size:12.0pt;font-family:"Arial",sans-serif'>COOKIE POLICY</span></b></p>

<p class=MsoNormal style='line-height:17.2pt'><span style='font-size:12.0pt'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:40.5pt;text-indent:-40.5pt'><b><span
style='font-family:"Arial",sans-serif'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-family:"Arial",sans-serif'>About the
Application</span></b></p>

<p class=MsoNormal style='line-height:9.35pt'><b><span style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:37.0pt;margin-bottom:
0cm;margin-left:80.5pt;margin-bottom:.0001pt;text-indent:-40.0pt;line-height:
141%'><span style='font-family:"Arial",sans-serif'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>Welcome to NaGringa
(the '<b>Application</b>'). The Application NaGringa (the '<b>Services</b> ').</span></p>

<p class=MsoNormal style='line-height:4.25pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:80.5pt;text-indent:-40.0pt;line-height:
128%'><span style='font-family:"Arial",sans-serif'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>The Application is
operated by NAGRINGA PTY LTD (ACN 656885273) . Access to and use of the Application,
or any of its associated Products or Services, is provided by NAGRINGA. Please
read these terms and conditions (the '<b>Terms</b> ') carefully. By using,
browsing and/or reading the Application, this signifies that you have read,
understood and agree to be bound by the Terms. If you do not agree with the
Terms, you must cease usage of the Application, or any of its Services,
immediately.</span></p>

<p class=MsoNormal style='line-height:5.0pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:3.0pt;margin-bottom:0cm;
margin-left:80.5pt;margin-bottom:.0001pt;text-indent:-40.0pt;line-height:129%'><span
style='font-family:"Arial",sans-serif'>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>NAGRINGA reserves
the right to review and change any of the Terms by updating this page at its
sole discretion. When NAGRINGA updates the Terms, it will use reasonable
endeavours to provide you with notice of updates to the Terms. Any changes to
the Terms take immediate effect from the date of their publication. Before you
continue, we recommend you keep a copy of the Terms for your records.</span></p>

<p class=MsoNormal style='line-height:12.95pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:40.5pt;text-indent:-40.5pt'><b><span
style='font-family:"Arial",sans-serif'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-family:"Arial",sans-serif'>Acceptance of
the Terms</span></b></p>

<p class=MsoNormal style='line-height:9.35pt'><b><span style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:8.0pt;margin-bottom:0cm;
margin-left:40.5pt;margin-bottom:.0001pt;line-height:133%'><span
style='font-family:"Arial",sans-serif'>You accept the Terms by using or
browsing the Application. You may also accept the Terms by clicking to accept
or agree to the Terms where this option is made available to you by NAGRINGA in
the user interface.</span></p>

<p class=MsoNormal style='line-height:10.2pt'><b><span style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-left:40.5pt;text-indent:-40.5pt'><b><span
style='font-family:"Arial",sans-serif'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-family:"Arial",sans-serif'>Registration
to use the Services</span></b></p>

<p class=MsoNormal style='line-height:9.35pt'><b><span style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:13.0pt;margin-bottom:
0cm;margin-left:80.5pt;margin-bottom:.0001pt;text-indent:-40.0pt;line-height:
141%'><span style='font-family:"Arial",sans-serif'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>In order to access
the Services, you must first register for an account through the Application
(the '<b>Account</b>').</span></p>

<p class=MsoNormal style='line-height:4.25pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:18.0pt;margin-bottom:
0cm;margin-left:80.5pt;margin-bottom:.0001pt;text-indent:-40.0pt;line-height:
133%'><span style='font-family:"Arial",sans-serif'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>As part of the
registration process, or as part of your continued use of the Services, you may
be required to provide personal information about yourself (such as
identification or contact details), including:</span></p>

<p class=MsoNormal style='line-height:5.2pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:17.0pt;margin-bottom:
0cm;margin-left:80.5pt;margin-bottom:.0001pt;text-indent:-40.0pt;line-height:
133%'><span style='font-family:"Arial",sans-serif'>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>You warrant that any
information you give to NAGRINGA in the course of completing the registration
process will always be accurate, correct and up to date.</span></p>

<p class=MsoNormal style='line-height:4.2pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:113.5pt;text-indent:-33.0pt'><span
style='font-family:"Arial",sans-serif'>(i)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>Email address</span></p>

<p class=MsoNormal style='line-height:11.35pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:113.5pt;text-indent:-33.0pt'><span
style='font-family:"Arial",sans-serif'>(ii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>Telephone number</span></p>

<p class=MsoNormal style='line-height:10.35pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:113.5pt;text-indent:-33.0pt'><span
style='font-family:"Arial",sans-serif'>(iii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>Name and Profile
Picture</span></p>

<p class=MsoNormal style='line-height:17.35pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:24.0pt;margin-bottom:
0cm;margin-left:80.5pt;margin-bottom:.0001pt;text-indent:-40.0pt;line-height:
152%'><span style='font-size:10.5pt;line-height:152%;font-family:"Arial",sans-serif'>(d)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.5pt;line-height:152%;font-family:"Arial",sans-serif'>Once
you have completed the registration process, you will be a registered member of
the Application ('<b>Member</b> ') and agree to be bound by the Terms.</span></p>

</div>

<span style='font-size:11.0pt;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection2>

<p class=MsoNormal style='line-height:4.95pt'><a name=page2></a><span
style='font-size:10.0pt'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:80.5pt;text-indent:-40.0pt'><span
style='font-family:"Arial",sans-serif'>(e)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>You may not use the
Services and may not accept the Terms if:</span></p>

<p class=MsoNormal style='line-height:10.35pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:113.5pt;text-indent:-33.0pt'><span
style='font-family:"Arial",sans-serif'>(i)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>you are not of legal
age to form a binding contract with NAGRINGA; or</span></p>

<p class=MsoNormal style='line-height:9.35pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:17.0pt;margin-bottom:
0cm;margin-left:113.5pt;margin-bottom:.0001pt;text-indent:-33.0pt;line-height:
133%'><span style='font-family:"Arial",sans-serif'>(ii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>you are a person
barred from receiving the Services under the laws of Australia or other
countries including the country in which you are resident or from which you use
the Services.</span></p>

<p class=MsoNormal style='line-height:12.2pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:40.5pt;text-indent:-40.5pt'><b><span
style='font-family:"Arial",sans-serif'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-family:"Arial",sans-serif'>Your
obligations as a Member</span></b></p>

<p class=MsoNormal style='line-height:3.35pt'><b><span style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-left:80.5pt;text-indent:-40.0pt'><span
style='font-family:"Arial",sans-serif'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>As a Member, you
agree to comply with the following:</span></p>

<p class=MsoNormal style='line-height:9.35pt'><span style='font-size:10.0pt'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:113.5pt;text-indent:-33.0pt'><span
style='font-family:"Arial",sans-serif'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>you will use the
Services only for purposes that are permitted by:</span></p>

<p class=MsoNormal style='line-height:10.35pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:146.5pt;text-indent:-33.0pt'><span
style='font-family:"Arial",sans-serif'>(i)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>the Terms; and</span></p>

<p class=MsoNormal style='line-height:10.35pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:15.0pt;margin-bottom:
0cm;margin-left:146.5pt;margin-bottom:.0001pt;text-indent:-33.0pt;line-height:
141%'><span style='font-family:"Arial",sans-serif'>(ii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>any applicable law,
regulation or generally accepted practices or guidelines in the relevant
jurisdictions;</span></p>

<p class=MsoNormal style='line-height:4.25pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:15.0pt;margin-bottom:
0cm;margin-left:113.5pt;margin-bottom:.0001pt;text-indent:-33.0pt;line-height:
133%'><span style='font-family:"Arial",sans-serif'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>you have the sole
responsibility for protecting the confidentiality of your password and/or email
address. Use of your password by any other person may result in the immediate
cancellation of the Services;</span></p>

<p class=MsoNormal style='line-height:5.2pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:7.0pt;margin-bottom:0cm;
margin-left:113.5pt;margin-bottom:.0001pt;text-indent:-33.0pt;line-height:131%'><span
style='font-family:"Arial",sans-serif'>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>any use of your
registration information by any other person, or third parties, is strictly
prohibited. You agree to immediately notify NAGRINGA of any unauthorised use of
your password or email address or any breach of security of which you have
become aware;</span></p>

<p class=MsoNormal style='line-height:5.55pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:4.0pt;margin-bottom:0cm;
margin-left:113.5pt;margin-bottom:.0001pt;text-indent:-33.0pt;line-height:133%'><span
style='font-family:"Arial",sans-serif'>(d)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>access and use of
the Application is limited, non-transferable and allows for the sole use of the
Application by you for the purposes of NAGRINGA providing the Services;</span></p>

<p class=MsoNormal style='line-height:5.2pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:16.0pt;margin-bottom:
0cm;margin-left:113.5pt;margin-bottom:.0001pt;text-indent:-33.0pt;line-height:
133%'><span style='font-family:"Arial",sans-serif'>(e)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>you will not use the
Services or the Application in connection with any commercial endeavours except
those that are specifically endorsed or approved by the management of NAGRINGA;</span></p>

<p class=MsoNormal style='line-height:5.2pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:6.0pt;margin-bottom:0cm;
margin-left:113.5pt;margin-bottom:.0001pt;text-indent:-33.0pt;line-height:131%'><span
style='font-family:"Arial",sans-serif'>(f)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>you will not use the
Services or Application for any illegal and/or unauthorised use which includes
collecting email addresses of Members by electronic or other means for the
purpose of sending unsolicited email or unauthorised framing of or linking to
the Application;</span></p>

<p class=MsoNormal style='line-height:5.55pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:113.5pt;text-indent:-33.0pt;line-height:
130%'><span style='font-family:"Arial",sans-serif'>(g)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>you agree that commercial
advertisements, affiliate links, and other forms of solicitation may be removed
from the Application without notice and may result in termination of the
Services. Appropriate legal action will be taken by NAGRINGA for any illegal or
unauthorised use of the Application; and</span></p>

<p class=MsoNormal style='line-height:3.75pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:6.0pt;margin-bottom:0cm;
margin-left:113.5pt;margin-bottom:.0001pt;text-indent:-33.0pt;line-height:141%'><span
style='font-family:"Arial",sans-serif'>(h)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>you acknowledge and
agree that any automated use of the Application or its Services is prohibited.</span></p>

<p class=MsoNormal style='line-height:11.25pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:40.5pt;text-indent:-40.5pt'><b><span
style='font-family:"Arial",sans-serif'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-family:"Arial",sans-serif'>Payment</span></b></p>

</div>

<span style='font-size:11.0pt;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection3>

<p class=MsoNormal style='line-height:6.95pt'><a name=page3></a><span
style='font-size:10.0pt'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:10.0pt;margin-bottom:
0cm;margin-left:80.5pt;margin-bottom:.0001pt;text-indent:-40.0pt;line-height:
141%'><span style='font-family:"Arial",sans-serif'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>Where the option is
given to you, you may make payment for the Services (the '<b>Services Fee</b>')
by way of:</span></p>

<p class=MsoNormal style='line-height:4.25pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:80.5pt;text-indent:-40.0pt;line-height:
139%'><span style='font-size:10.5pt;line-height:139%;font-family:"Arial",sans-serif'>(b)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.5pt;line-height:139%;font-family:"Arial",sans-serif'>All
payments made in the course of your use of the Services are made using . In
using the Application, the Services or when making any payment in relation to
your use of the Services, you warrant that you have read, understood and agree
to be bound by the terms and conditions which are available on their
Application.</span></p>

<p class=MsoNormal style='line-height:4.75pt'><span style='font-size:10.5pt;
font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:16.0pt;margin-bottom:
0cm;margin-left:80.5pt;margin-bottom:.0001pt;text-indent:-40.0pt;line-height:
139%'><span style='font-size:10.5pt;line-height:139%;font-family:"Arial",sans-serif'>(c)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.5pt;line-height:139%;font-family:"Arial",sans-serif'>You
acknowledge and agree that where a request for the payment of the Services Fee
is returned or denied, for whatever reason, by your financial institution or is
unpaid by you for any other reason, then you are liable for any costs,
including banking fees and charges, associated with the Services Fee.</span></p>

<p class=MsoNormal style='line-height:2.75pt'><span style='font-size:10.5pt;
font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:6.0pt;margin-bottom:0cm;
margin-left:80.5pt;margin-bottom:.0001pt;text-indent:-40.0pt;line-height:141%'><span
style='font-family:"Arial",sans-serif'>(d)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>You agree and
acknowledge that NAGRINGA can vary the Services Fee at any time .</span></p>

<p class=MsoNormal style='line-height:11.25pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:40.5pt;text-indent:-40.5pt'><b><span
style='font-family:"Arial",sans-serif'>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-family:"Arial",sans-serif'>Refund Policy</span></b></p>

<p class=MsoNormal style='line-height:9.35pt'><b><span style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:2.0pt;margin-bottom:0cm;
margin-left:40.5pt;margin-bottom:.0001pt;line-height:131%'><span
style='font-family:"Arial",sans-serif'>NAGRINGA will only provide you with a
refund of the Services Fee in the event they are unable to continue to provide
the Services or if the manager of NAGRINGA makes a decision, at its absolute
discretion, that it is reasonable to do so under the circumstances (the '<b>Refund</b>').</span></p>

<p class=MsoNormal style='line-height:10.55pt'><b><span style='font-family:
"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-left:40.5pt;text-indent:-40.5pt'><b><span
style='font-family:"Arial",sans-serif'>7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-family:"Arial",sans-serif'>Copyright and
Intellectual Property</span></b></p>

<p class=MsoNormal style='line-height:10.35pt'><b><span style='font-family:
"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:10.0pt;margin-bottom:
0cm;margin-left:80.5pt;margin-bottom:.0001pt;text-indent:-40.0pt;line-height:
135%'><span style='font-size:10.5pt;line-height:135%;font-family:"Arial",sans-serif'>(a)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.5pt;line-height:135%;font-family:"Arial",sans-serif'>The
Application, the Services and all of the related products of NAGRINGA are
subject to copyright. The material on the Application is protected by copyright
under the laws of Australia and through international treaties. Unless
otherwise indicated, all rights (including copyright) in the Services and
compilation of the Application (including but not limited to text, graphics,
logos, button icons, video images, audio clips, Application, code, scripts,
design elements and interactive features) or the Services are owned or
controlled for these purposes, and are reserved by NAGRINGA or its
contributors.</span></p>

<p class=MsoNormal style='line-height:5.15pt'><span style='font-size:10.5pt;
font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:3.0pt;margin-bottom:0cm;
margin-left:80.5pt;margin-bottom:.0001pt;text-indent:-40.0pt;line-height:133%'><span
style='font-family:"Arial",sans-serif'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>All trademarks,
service marks and trade names are owned, registered and/or licensed by
NAGRINGA, who grants to you a worldwide, non-exclusive, royalty-free, revocable
license whilst you are a Member to:</span></p>

<p class=MsoNormal style='line-height:3.2pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:113.5pt;text-indent:-33.0pt'><span
style='font-family:"Arial",sans-serif'>(i)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>use the Application
pursuant to the Terms;</span></p>

<p class=MsoNormal style='line-height:11.35pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:53.0pt;margin-bottom:
0cm;margin-left:113.5pt;margin-bottom:.0001pt;text-indent:-33.0pt;line-height:
141%'><span style='font-family:"Arial",sans-serif'>(ii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>copy and store the
Application and the material contained in the Application in your device's
cache memory; and</span></p>

<p class=MsoNormal style='line-height:3.25pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:51.0pt;margin-bottom:
0cm;margin-left:113.5pt;margin-bottom:.0001pt;text-indent:-33.0pt;line-height:
141%'><span style='font-family:"Arial",sans-serif'>(iii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>print pages from the
Application for your own personal and non-commercial use.</span></p>

<p class=MsoNormal style='line-height:8.25pt'><span style='font-size:10.0pt'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:19.0pt;margin-bottom:
0cm;margin-left:80.5pt;margin-bottom:.0001pt;line-height:141%'><span
style='font-family:"Arial",sans-serif'>NAGRINGA does not grant you any other
rights whatsoever in relation to the Application or the Services. All other
rights are expressly reserved by</span></p>

</div>

<span style='font-size:11.0pt;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection4>

<p class=MsoNormal style='margin-left:80.5pt'><a name=page4></a><span
style='font-family:"Arial",sans-serif'>NAGRINGA.</span></p>

<p class=MsoNormal style='line-height:16.35pt'><span style='font-size:10.0pt'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:13.0pt;margin-bottom:
0cm;margin-left:80.5pt;margin-bottom:.0001pt;text-indent:-40.0pt;line-height:
133%'><span style='font-family:"Arial",sans-serif'>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>NAGRINGA retains all
rights, title and interest in and to the Application and all related Services.
Nothing you do on or in relation to the Application will transfer any:</span></p>

<p class=MsoNormal style='line-height:4.2pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:35.0pt;margin-bottom:
0cm;margin-left:113.5pt;margin-bottom:.0001pt;text-indent:-33.0pt;line-height:
141%'><span style='font-family:"Arial",sans-serif'>(i)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>business name,
trading name, domain name, trade mark, industrial design, patent, registered
design or copyright, or</span></p>

<p class=MsoNormal style='line-height:4.25pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:18.0pt;margin-bottom:
0cm;margin-left:113.5pt;margin-bottom:.0001pt;text-indent:-33.0pt;line-height:
141%'><span style='font-family:"Arial",sans-serif'>(ii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>a right to use or
exploit a business name, trading name, domain name, trade mark or industrial
design, or</span></p>

<p class=MsoNormal style='line-height:3.25pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:29.0pt;margin-bottom:
0cm;margin-left:113.5pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;text-indent:-33.0pt;line-height:133%'><span style='font-family:
"Arial",sans-serif'>(iii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>a thing, system or
process that is the subject of a patent, registered design or copyright (or an
adaptation or modification of such a thing, system or process),</span></p>

<p class=MsoNormal style='line-height:9.25pt'><span style='font-size:10.0pt'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:80.5pt'><span style='font-family:"Arial",sans-serif'>to
you.</span></p>

<p class=MsoNormal style='line-height:15.35pt'><span style='font-size:10.0pt'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:1.0pt;margin-bottom:0cm;
margin-left:80.5pt;margin-bottom:.0001pt;text-indent:-40.0pt;line-height:136%'><span
style='font-size:10.5pt;line-height:136%;font-family:"Arial",sans-serif'>(d)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.5pt;line-height:136%;font-family:"Arial",sans-serif'>You
may not, without the prior written permission of NAGRINGA and the permission of
any other relevant rights owners: broadcast, republish, upload to a third
party, transmit, post, distribute, show or play in public, adapt or change in
any way the Services or third party Services for any purpose, unless otherwise
provided by these Terms. This prohibition does not extend to materials on the
Application, which are freely available for re-use or are in the public domain.</span></p>

<p class=MsoNormal style='line-height:12.25pt'><span style='font-size:10.5pt;
font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:40.5pt;text-indent:-40.5pt'><b><span
style='font-family:"Arial",sans-serif'>8.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-family:"Arial",sans-serif'>Privacy</span></b></p>

<p class=MsoNormal style='line-height:9.35pt'><b><span style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:1.0pt;margin-bottom:0cm;
margin-left:40.5pt;margin-bottom:.0001pt;line-height:133%'><span
style='font-family:"Arial",sans-serif'>NAGRINGA takes your privacy seriously
and any information provided through your use of the Application and/or
Services are subject to NAGRINGA's Privacy Policy, which is available at
https://www.nagringa.app/legal/privacy-policy.</span></p>

<p class=MsoNormal style='line-height:10.2pt'><b><span style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-left:40.5pt;text-indent:-40.5pt'><b><span
style='font-family:"Arial",sans-serif'>9.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-family:"Arial",sans-serif'>General
Disclaimer</span></b></p>

<p class=MsoNormal style='line-height:9.35pt'><b><span style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:6.0pt;margin-bottom:0cm;
margin-left:80.5pt;margin-bottom:.0001pt;text-indent:-40.0pt;line-height:131%'><span
style='font-family:"Arial",sans-serif'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>Nothing in the Terms
limits or excludes any guarantees, warranties, representations or conditions
implied or imposed by law, including the Australian Consumer Law (or any
liability under them) which by law may not be limited or excluded.</span></p>

<p class=MsoNormal style='line-height:5.55pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:80.5pt;text-indent:-40.0pt'><span
style='font-family:"Arial",sans-serif'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>Subject to this
clause, and to the extent permitted by law:</span></p>

<p class=MsoNormal style='line-height:10.35pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:1.0pt;margin-bottom:0cm;
margin-left:113.5pt;margin-bottom:.0001pt;text-indent:-33.0pt;line-height:141%'><span
style='font-family:"Arial",sans-serif'>(i)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>all terms,
guarantees, warranties, representations or conditions which are not expressly
stated in the Terms are excluded; and</span></p>

<p class=MsoNormal style='line-height:3.25pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:113.5pt;text-indent:-33.0pt;line-height:
136%'><span style='font-size:10.5pt;line-height:136%;font-family:"Arial",sans-serif'>(ii)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.5pt;line-height:136%;font-family:"Arial",sans-serif'>NAGRINGA
will not be liable for any special, indirect or consequential loss or damage
(unless such loss or damage is reasonably foreseeable resulting from our
failure to meet an applicable Consumer Guarantee), loss of profit or
opportunity, or damage to goodwill arising out of or in connection with the
Services or these Terms (including as a result of not being able to use the
Services or the late supply of the Services), whether</span></p>

</div>

<span style='font-size:11.0pt;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection5>

<p class=MsoNormal style='line-height:.25pt'><a name=page5></a><span
style='font-size:10.0pt'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:28.0pt;margin-bottom:
0cm;margin-left:113.5pt;margin-bottom:.0001pt;line-height:138%'><span
style='font-family:"Arial",sans-serif'>at common law, under contract, tort
(including negligence), in equity, pursuant to statute or otherwise.</span></p>

<p class=MsoNormal style='line-height:9.55pt'><span style='font-size:10.0pt'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:3.0pt;margin-bottom:0cm;
margin-left:80.5pt;margin-bottom:.0001pt;text-indent:-40.0pt;line-height:128%'><span
style='font-family:"Arial",sans-serif'>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>Use of the
Application and the Services is at your own risk. Everything on the Application
and the Services is provided to you &quot;as is&quot; and &quot;as
available&quot; without warranty or condition of any kind. None of the
affiliates, directors, officers, employees, agents, contributors and licensors
of NAGRINGA make any express or implied representation or warranty about the
Services or any products or Services (including the products or Services of
NAGRINGA) referred to on the Application. This includes (but is not restricted
to) loss or damage you might suffer as a result of any of the following:</span></p>

<p class=MsoNormal style='line-height:5.1pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:113.5pt;text-indent:-33.0pt;line-height:
130%'><span style='font-family:"Arial",sans-serif'>(i)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>failure of
performance, error, omission, interruption, deletion, defect, failure to
correct defects, delay in operation or transmission, computer virus or other
harmful component, loss of data, communication line failure, unlawful third
party conduct, or theft, destruction, alteration or unauthorised access to
records;</span></p>

<p class=MsoNormal style='line-height:5.75pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:3.0pt;margin-bottom:0cm;
margin-left:113.5pt;margin-bottom:.0001pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-33.0pt;line-height:133%'><span style='font-family:"Arial",sans-serif'>(ii)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>the accuracy,
suitability or currency of any information on the Application, the Services, or
any of its Services related products (including third party material and
advertisements on the Application);</span></p>

<p class=MsoNormal style='line-height:5.2pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:18.0pt;margin-bottom:
0cm;margin-left:113.5pt;margin-bottom:.0001pt;text-indent:-33.0pt;line-height:
141%'><span style='font-family:"Arial",sans-serif'>(iii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>costs incurred as a
result of you using the Application, the Services or any of the products of
NAGRINGA; and</span></p>

<p class=MsoNormal style='line-height:3.25pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:10.0pt;margin-bottom:
0cm;margin-left:113.5pt;margin-bottom:.0001pt;text-indent:-33.0pt;line-height:
141%'><span style='font-family:"Arial",sans-serif'>(iv)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>the Services or
operation in respect to links which are provided for your convenience.</span></p>

<p class=MsoNormal style='line-height:10.25pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:40.5pt;text-indent:-40.5pt'><b><span
style='font-family:"Arial",sans-serif'>10.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-family:"Arial",sans-serif'>Competitors</span></b></p>

<p class=MsoNormal style='line-height:9.35pt'><b><span style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:1.0pt;margin-bottom:0cm;
margin-left:40.5pt;margin-bottom:.0001pt;line-height:129%'><span
style='font-family:"Arial",sans-serif'>If you are in the business of providing
similar Services for the purpose of providing them to users for a commercial
gain, whether business users or domestic users, then you are a competitor of
NAGRINGA. Competitors are not permitted to use or access any information or
content on our Application. If you breach this provision, NAGRINGA will hold
you fully responsible for any loss that we may sustain and hold you accountable
for all profits that you might make from such a breach.</span></p>

<p class=MsoNormal style='line-height:10.95pt'><b><span style='font-family:
"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-left:40.5pt;text-indent:-40.5pt'><b><span
style='font-family:"Arial",sans-serif'>11.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-family:"Arial",sans-serif'>Limitation of
liability</span></b></p>

<p class=MsoNormal style='line-height:10.35pt'><b><span style='font-family:
"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:3.0pt;margin-bottom:0cm;
margin-left:80.5pt;margin-bottom:.0001pt;text-indent:-40.0pt;line-height:131%'><span
style='font-family:"Arial",sans-serif'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>NAGRINGA's total
liability arising out of or in connection with the Services or these Terms,
however arising, including under contract, tort (including negligence), in
equity, under statute or otherwise, will not exceed the resupply of the
Services to you.</span></p>

<p class=MsoNormal style='line-height:4.55pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:6.0pt;margin-bottom:0cm;
margin-left:80.5pt;margin-bottom:.0001pt;text-indent:-40.0pt;line-height:139%'><span
style='font-size:10.5pt;line-height:139%;font-family:"Arial",sans-serif'>(b)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.5pt;line-height:139%;font-family:"Arial",sans-serif'>You
expressly understand and agree that NAGRINGA, its affiliates, employees,
agents, contributors and licensors shall not be liable to you for any direct,
indirect, incidental, special consequential or exemplary damages which may be
incurred by you, however caused and under any theory of liability. This shall</span></p>

</div>

<span style='font-size:11.0pt;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection6>

<p class=MsoNormal style='line-height:.4pt'><a name=page6></a><span
style='font-size:10.0pt'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:13.0pt;margin-bottom:
0cm;margin-left:80.5pt;margin-bottom:.0001pt;line-height:132%'><span
style='font-family:"Arial",sans-serif'>include, but is not limited to, any loss
of profit (whether incurred directly or indirectly), any loss of goodwill or
business reputation and any other intangible loss.</span></p>

<p class=MsoNormal style='line-height:12.4pt'><span style='font-size:10.0pt'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:40.5pt;text-indent:-40.5pt'><b><span
style='font-family:"Arial",sans-serif'>12.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-family:"Arial",sans-serif'>Termination
of Contract</span></b></p>

<p class=MsoNormal style='line-height:9.35pt'><b><span style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:2.0pt;margin-bottom:0cm;
margin-left:80.5pt;margin-bottom:.0001pt;text-indent:-40.0pt;line-height:141%'><span
style='font-family:"Arial",sans-serif'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>The Terms will
continue to apply until terminated by either you or by NAGRINGA as set out
below.</span></p>

<p class=MsoNormal style='line-height:4.25pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:80.5pt;text-indent:-40.0pt'><span
style='font-family:"Arial",sans-serif'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>If you want to
terminate the Terms, you may do so by:</span></p>

<p class=MsoNormal style='line-height:10.35pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:113.5pt;text-indent:-33.0pt'><span
style='font-family:"Arial",sans-serif'>(i)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>not renewing the
Subscription prior to the end of the Subscription Period;</span></p>

<p class=MsoNormal style='line-height:11.35pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:15.0pt;margin-bottom:
0cm;margin-left:113.5pt;margin-bottom:.0001pt;text-indent:-33.0pt;line-height:
141%'><span style='font-family:"Arial",sans-serif'>(ii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>providing NAGRINGA
with 0 days' notice of your intention to terminate; and</span></p>

<p class=MsoNormal style='line-height:3.25pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:36.0pt;margin-bottom:
0cm;margin-left:113.5pt;margin-bottom:.0001pt;text-indent:-33.0pt;line-height:
141%'><span style='font-family:"Arial",sans-serif'>(iii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>closing your
accounts for all of the services which you use, where NAGRINGA has made this
option available to you.</span></p>

<p class=MsoNormal style='line-height:8.25pt'><span style='font-size:10.0pt'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:5.0pt;margin-bottom:0cm;
margin-left:80.5pt;margin-bottom:.0001pt;line-height:141%'><span
style='font-family:"Arial",sans-serif'>Your notice should be sent, in writing,
to NAGRINGA via the 'Contact Us' link on our homepage.</span></p>

<p class=MsoNormal style='line-height:9.25pt'><span style='font-size:10.0pt'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:80.5pt;text-indent:-40.0pt'><span
style='font-family:"Arial",sans-serif'>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>NAGRINGA may at any
time, terminate the Terms with you if:</span></p>

<p class=MsoNormal style='line-height:10.35pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:113.5pt;text-indent:-33.0pt'><span
style='font-family:"Arial",sans-serif'>(i)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>you do not renew the
Subscription at the end of the Subscription Period;</span></p>

<p class=MsoNormal style='line-height:11.35pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:16.0pt;margin-bottom:
0cm;margin-left:113.5pt;margin-bottom:.0001pt;text-indent:-33.0pt;line-height:
141%'><span style='font-family:"Arial",sans-serif'>(ii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>you have breached
any provision of the Terms or intend to breach any provision;</span></p>

<p class=MsoNormal style='line-height:4.25pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:113.5pt;text-indent:-33.0pt'><span
style='font-family:"Arial",sans-serif'>(iii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>NAGRINGA is required
to do so by law;</span></p>

<p class=MsoNormal style='line-height:10.35pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:16.0pt;margin-bottom:
0cm;margin-left:113.5pt;margin-bottom:.0001pt;text-indent:-33.0pt;line-height:
141%'><span style='font-family:"Arial",sans-serif'>(iv)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>the provision of the
Services to you by NAGRINGA is, in the opinion of NAGRINGA, no longer
commercially viable.</span></p>

<p class=MsoNormal style='line-height:9.25pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:80.5pt;text-indent:-40.0pt;line-height:
129%'><span style='font-family:"Arial",sans-serif'>(d)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>Subject to local applicable
laws, NAGRINGA reserves the right to discontinue or cancel your membership at
any time and may suspend or deny, in its sole discretion, your access to all or
any portion of the Application or the Services without notice if you breach any
provision of the Terms or any applicable law or if your conduct impacts
NAGRINGA's name or reputation or violates the rights of those of another party.</span></p>

<p class=MsoNormal style='line-height:11.95pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:40.5pt;text-indent:-40.5pt'><b><span
style='font-family:"Arial",sans-serif'>13.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-family:"Arial",sans-serif'>Indemnity</span></b></p>

<p class=MsoNormal style='line-height:3.35pt'><b><span style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:33.0pt;margin-bottom:
0cm;margin-left:80.5pt;margin-bottom:.0001pt;text-indent:-40.0pt;line-height:
141%'><span style='font-family:"Arial",sans-serif'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>You agree to
indemnify NAGRINGA, its affiliates, employees, agents, contributors, third
party content providers and licensors from and against:</span></p>

<p class=MsoNormal style='line-height:3.25pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:8.0pt;margin-bottom:0cm;
margin-left:113.5pt;margin-bottom:.0001pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-33.0pt;line-height:133%'><span style='font-family:"Arial",sans-serif'>(i)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>all actions, suits,
claims, demands, liabilities, costs, expenses, loss and damage (including legal
fees on a full indemnity basis) incurred, suffered or arising out of or in
connection with Your Content;</span></p>

<p class=MsoNormal style='line-height:5.2pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:113.5pt;text-indent:-33.0pt'><span
style='font-family:"Arial",sans-serif'>(ii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>any direct or
indirect consequences of you accessing, using or</span></p>

</div>

<span style='font-size:11.0pt;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection7>

<p class=MsoNormal style='margin-left:113.5pt'><a name=page7></a><span
style='font-family:"Arial",sans-serif'>transacting on the Application or
attempts to do so; and/or</span></p>

<p class=MsoNormal style='line-height:10.45pt'><span style='font-size:10.0pt'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:113.5pt;text-indent:-33.0pt'><span
style='font-family:"Arial",sans-serif'>(iii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>any breach of the
Terms.</span></p>

<p class=MsoNormal style='line-height:17.35pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:40.5pt;text-indent:-40.5pt'><b><span
style='font-family:"Arial",sans-serif'>14.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-family:"Arial",sans-serif'>Dispute
Resolution</span></b></p>

<p class=MsoNormal style='line-height:10.35pt'><b><span style='font-family:
"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-left:80.5pt;text-indent:-40.0pt'><span
style='font-family:"Arial",sans-serif'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>Compulsory:</span></p>

<p class=MsoNormal style='line-height:9.35pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:80.5pt;line-height:131%'><span
style='font-family:"Arial",sans-serif'>If a dispute arises out of or relates to
the Terms, either party may not commence any Tribunal or Court proceedings in
relation to the dispute, unless the following clauses have been complied with
(except where urgent interlocutory relief is sought).</span></p>

<p class=MsoNormal style='line-height:10.55pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:80.5pt;text-indent:-40.0pt'><span
style='font-family:"Arial",sans-serif'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>Notice:</span></p>

<p class=MsoNormal style='line-height:9.35pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:3.0pt;margin-bottom:0cm;
margin-left:80.5pt;margin-bottom:.0001pt;text-align:justify;text-justify:inter-ideograph;
line-height:133%'><span style='font-family:"Arial",sans-serif'>A party to the
Terms claiming a dispute ('<b>Dispute</b>') has arisen under the Terms, must
give written notice to the other party detailing the nature of the dispute, the
desired outcome and the action required to settle the Dispute.</span></p>

<p class=MsoNormal style='line-height:10.2pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:80.5pt;text-indent:-40.0pt'><span
style='font-family:"Arial",sans-serif'>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>Resolution:</span></p>

<p class=MsoNormal style='line-height:9.35pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:10.0pt;margin-bottom:
0cm;margin-left:80.5pt;margin-bottom:.0001pt;line-height:141%'><span
style='font-family:"Arial",sans-serif'>On receipt of that notice ('<b>Notice</b>')
by that other party, the parties to the Terms ('<b>Parties</b> ') must:</span></p>

<p class=MsoNormal style='line-height:8.25pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:21.0pt;margin-bottom:
0cm;margin-left:113.5pt;margin-bottom:.0001pt;text-indent:-33.0pt;line-height:
133%'><span style='font-family:"Arial",sans-serif'>(i)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>Within 31 days of
the Notice endeavour in good faith to resolve the Dispute expeditiously by
negotiation or such other means upon which they may mutually agree;</span></p>

<p class=MsoNormal style='line-height:5.25pt'><span style='font-size:10.0pt'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:10.0pt;margin-bottom:
0cm;margin-left:113.5pt;margin-bottom:.0001pt;text-indent:-33.0pt;line-height:
130%'><span style='font-family:"Arial",sans-serif'>(ii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>If for any reason
whatsoever, 31 days after the date of the Notice, the Dispute has not been
resolved, the Parties must either agree upon selection of a mediator or request
that an appropriate mediator be appointed by the President of the South
Queensland Dispute Resolution Centre or his or her nominee;</span></p>

<p class=MsoNormal style='line-height:5.75pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:9.0pt;margin-bottom:0cm;
margin-left:113.5pt;margin-bottom:.0001pt;text-indent:-33.0pt;line-height:130%'><span
style='font-family:"Arial",sans-serif'>(iii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>The Parties are
equally liable for the fees and reasonable expenses of a mediator and the cost
of the venue of the mediation and without limiting the foregoing undertake to
pay any amounts requested by the mediator as a pre-condition to the mediation
commencing. The Parties must each pay their own costs associated with the
mediation;</span></p>

<p class=MsoNormal style='line-height:3.75pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:113.5pt;text-indent:-33.0pt'><span
style='font-family:"Arial",sans-serif'>(iv)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>The mediation will
be held in Brisbane - Queensland, Australia.</span></p>

<p class=MsoNormal style='line-height:18.35pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:80.5pt;text-indent:-40.0pt'><span
style='font-family:"Arial",sans-serif'>(d)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>Confidential</span></p>

<p class=MsoNormal style='line-height:9.35pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:12.0pt;margin-bottom:
0cm;margin-left:80.5pt;margin-bottom:.0001pt;line-height:131%'><span
style='font-family:"Arial",sans-serif'>All communications concerning
negotiations made by the Parties arising out of and in connection with this
dispute resolution clause are confidential and to the extent possible, must be
treated as &quot;without prejudice&quot; negotiations for the purpose of
applicable laws of evidence.</span></p>

</div>

<span style='font-size:11.0pt;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection8>

<p class=MsoNormal style='margin-left:80.5pt;text-indent:-40.0pt'><a
name=page8></a><span style='font-family:"Arial",sans-serif'>(e)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>Termination of
Mediation:</span></p>

<p class=MsoNormal style='line-height:9.35pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:12.0pt;margin-bottom:
0cm;margin-left:80.5pt;margin-bottom:.0001pt;line-height:133%'><span
style='font-family:"Arial",sans-serif'>If 31 days have elapsed after the start
of a mediation of the Dispute and the Dispute has not been resolved, either
Party may ask the mediator to terminate the mediation and the mediator must do
so.</span></p>

<p class=MsoNormal style='line-height:17.2pt'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:40.5pt;text-indent:-40.5pt'><b><span
style='font-family:"Arial",sans-serif'>15.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-family:"Arial",sans-serif'>Venue and
Jurisdiction</span></b></p>

<p class=MsoNormal style='line-height:9.35pt'><b><span style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:10.0pt;margin-bottom:
0cm;margin-left:40.5pt;margin-bottom:.0001pt;line-height:131%'><span
style='font-family:"Arial",sans-serif'>The Services offered by NAGRINGA is
intended to be viewed by residents of Australia. In the event of any dispute
arising out of or in relation to the Application, you agree that the exclusive
venue for resolving any dispute shall be in the courts of Queensland,
Australia.</span></p>

<p class=MsoNormal style='line-height:10.55pt'><b><span style='font-family:
"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-left:40.5pt;text-indent:-40.5pt'><b><span
style='font-family:"Arial",sans-serif'>16.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-family:"Arial",sans-serif'>Governing Law</span></b></p>

<p class=MsoNormal style='line-height:9.35pt'><b><span style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-left:40.5pt;line-height:128%'><span
style='font-family:"Arial",sans-serif'>The Terms are governed by the laws of
Queensland, Australia. Any dispute, controversy, proceeding or claim of
whatever nature arising out of or in any way relating to the Terms and the
rights created hereby shall be governed, interpreted and construed by, under and
pursuant to the laws of Queensland, Australia, without reference to conflict of
law principles, notwithstanding mandatory rules. The validity of this governing
law clause is not contested. The Terms shall be binding to the benefit of the
parties hereto and their successors and assigns.</span></p>

<p class=MsoNormal style='line-height:11.0pt'><b><span style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-left:40.5pt;text-indent:-40.5pt'><b><span
style='font-family:"Arial",sans-serif'>17.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-family:"Arial",sans-serif'>Independent
Legal Advice</span></b></p>

<p class=MsoNormal style='line-height:9.35pt'><b><span style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:2.0pt;margin-bottom:0cm;
margin-left:40.5pt;margin-bottom:.0001pt;line-height:131%'><span
style='font-family:"Arial",sans-serif'>Both parties confirm and declare that
the provisions of the Terms are fair and reasonable and both parties having
taken the opportunity to obtain independent legal advice and declare the Terms are
not against public policy on the grounds of inequality or bargaining power or
general grounds of restraint of trade.</span></p>

<p class=MsoNormal style='line-height:8.55pt'><b><span style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-left:40.5pt;text-indent:-40.5pt'><b><span
style='font-family:"Arial",sans-serif'>18.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-family:"Arial",sans-serif'>Severance</span></b></p>

<p class=MsoNormal style='line-height:9.35pt'><b><span style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:9.0pt;margin-bottom:0cm;
margin-left:40.5pt;margin-bottom:.0001pt;line-height:152%'><span
style='font-size:10.5pt;line-height:152%;font-family:"Arial",sans-serif'>If any
part of these Terms is found to be void or unenforceable by a Court of
competent jurisdiction, that part shall be severed and the rest of the Terms
shall remain in force.</span></p>

<p class=MsoNormal style='line-height:10.0pt'><span style='font-size:10.0pt'>&nbsp;</span></p>

<p class=MsoNormal style='line-height:10.0pt'><span style='font-size:10.0pt'>&nbsp;</span></p>

<p class=MsoNormal style='line-height:10.0pt'><span style='font-size:10.0pt'>&nbsp;</span></p>

<p class=MsoNormal style='line-height:10.0pt'><span style='font-size:10.0pt'>&nbsp;</span></p>

<p class=MsoNormal style='line-height:10.0pt'><span style='font-size:10.0pt'>&nbsp;</span></p>

<p class=MsoNormal style='line-height:16.05pt'><span style='font-size:10.0pt'>&nbsp;</span></p>
