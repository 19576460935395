import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IndexComponent } from './site/index/index.component';
import { CarnavalComponent } from './site/carnaval/carnaval.component';
import { ThiagoComponent } from './site/thiago/thiago.component';
import { LinkComponent } from './site/link/link.component';
import { DownloadComponent } from './site/download/download.component';
import { IndexComponent as LegalIndexComponent } from './site/legal/index/index.component';
import { TsAndCsComponent } from './site/legal/ts-and-cs/ts-and-cs.component';
import { PrivacyPolicyComponent } from './site/legal/privacy-policy/privacy-policy.component';
import { CookieComponent } from './site/legal/cookie/cookie.component';
import { EventComponent } from './site/event/event.component';

const routes: Routes = [
    {path: '', component: IndexComponent},
    {path: 'carnaval', component: CarnavalComponent},
    {path: 'thiago', component: ThiagoComponent},
    {path: 'pullup', component: DownloadComponent},
    {path: 'download', component: DownloadComponent},
    {path: 'event', component: EventComponent},
	{
		path: 'legal', children: [
			{
				path: '', component: LegalIndexComponent,
			},
			{
				path: 'ts-and-cs', component: TsAndCsComponent,
			},
			{
				path: 'privacy-policy', component: PrivacyPolicyComponent,
			},
			{
				path: 'cookies-policy', component: CookieComponent,
			},
		],

	},
    {path: 'l', component: LinkComponent},
	{
		path: 'l/:type/:linkId', component: LinkComponent
	},
	{
		path: 'l/:linkId', component: LinkComponent
	},
    {
        path: '**',
        redirectTo: ''
    }

];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
