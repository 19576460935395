import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  @ViewChild('iframe')
  public someInput!: ElementRef;


  constructor(private route: ActivatedRoute, private el: ElementRef) { }

  ngOnInit(): void {
    this.load();
  }

  private load(): void {
    const iFrame = this.someInput?.nativeElement as HTMLIFrameElement;
    console.log(iFrame);


    this.route.queryParamMap.subscribe(response => {
      const isLegal = response.get('legal') == 'true';
      if (isLegal) {
        const element = this.el.nativeElement as HTMLElement;
        element.classList.add('isLegal');
      }
    });
  }

}
