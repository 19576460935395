import { Component } from '@angular/core';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent {

  public url: String;

  ngOnInit() {

    var isiOS = /iPad|iPhone|iPod/i.test(navigator.userAgent);

    var isAndroid = /Android/i.test(navigator.userAgent);

    if (isiOS) {
      this.url = 'https://apps.apple.com/au/app/nagringa/id1608557362';
    } else if (isAndroid) {
      this.url = 'https://play.google.com/store/apps/details?id=app.nagringa.app';
    } else {
      this.url = 'https://promo.nagringa.app/download';
    }
  }
}
