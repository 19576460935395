import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-ts-and-cs',
  templateUrl: './ts-and-cs.component.html',
  styleUrls: ['./ts-and-cs.component.scss']
})
export class TsAndCsComponent implements OnInit {

  constructor(private route: ActivatedRoute, private el:ElementRef) { }

  ngOnInit(): void {

    this.route.queryParamMap.subscribe(response => {
      const isLegal = response.get('legal') == 'true';
      if (isLegal) {
        const element = this.el.nativeElement as HTMLElement;
        element.classList.add('isLegal');
      }
    });

  }

}
