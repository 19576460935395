<nav class="navbar navbar-style-two navbar-expand-lg navbar-light sticky-top">
    <div class="container-fluid">
        <div class="logo">
            <a routerLink="/home-nine">
                <img src="assets/img/logo.png" id="header-logo" class="black-logo" alt="Logo">
            </a>
        </div>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a href="/" class="nav-link">
                        Home
                    </a>
                </li>
                <li class="nav-item">
                    <a href="/#products" class="nav-link">
                        Products
                    </a>
                </li>
                <li class="nav-item">
                    <a href="/#about" class="nav-link">
                        About us
                    </a>
                </li>
                <li class="nav-item">
                    <a href="/#apply" class="nav-link">
                        Parnetship
                    </a>
                </li>
                <li class="nav-item">
                    <a href="/carnaval" class="nav-link">
                        Carnaval
                    </a>
                </li>
                <li class="nav-item">
                    <a href="/download" class="nav-link">
                        Download App
                    </a>
                </li>
            </ul>

            <div class="others-option">
                <div class="d-flex align-items-center">
                    <div class="option-item">
                        <a href="https://forms.gle/MVamXvFK55eeJKvp6" class="sign-up">Become a Partner</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</nav>


<main *ngIf="url">
    <div class="container">
        <section class="buttons">
            <a href="https://instagram.com/nagringa.app"  class="cta">
                Follow us on Instagram
            </a>
            <a [href]="url" class="cta">
                Download App
            </a>
        </section>

    </div>
</main>


<main  *ngIf="!url">
    <div class="container">
        <div class="row d-flex align-items-center">
            <!-- Left -->
            <div class="col-md-6 d-none d-lg-block d-md-block">
                <div class="qr-code">
                    <img src="/assets/img/qrcode-download.svg" alt="Content Creator" class="img-fluid wow fadeInRight"
                        data-wow-offset="1">
                </div>
            </div>
            <!-- Right -->
            <div class="col-md-5">
                <div class="content" id="">
                    <h6>Scan it to Download</h6>

                    <div class="store">
                        <a href="https://apps.apple.com/us/app/nagringa/id1608557362" target="_blank">
                            <img
                                src="https://res.cloudinary.com/nagringaapp/image/upload/v1702356562/images/download-apple_l894gg.png">
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=app.nagringa.app" target="_blank">
                            <img
                                src="https://res.cloudinary.com/nagringaapp/image/upload/v1702356562/images/download-google_l5k4bb.png">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>


<footer class="footer-style-area pt-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-sm-6 col-lg-3">
                <div class="single-footer-widget">
                    <div class="footer-logo">
                        <a href="#">
                            <img src="assets/img/logo-white.png" id="footer-logo" alt="Logo">
                        </a>
                        <p>Meaningful Connections, Elevated: Unleashing the Power of Our Interactive Business Platform!
                        </p>
                        <ul class="footer-social">
                            <li><a href="https://www.linkedin.com/company/nagringaapp"><i
                                        class='bx bxl-linkedin'></i></a></li>
                            <li><a href="https://www.instagram.com/nagringa.app"><i class='bx bxl-instagram'></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-2">
                <div class="single-footer-widget ps-3">
                    <h3>Quick Links</h3>

                    <ul class="quick-link">
                        <li>
                            <a href="/#products">Products</a>
                        </li>
                        <li>
                            <a href="/#about">About Us</a>
                        </li>
                        <li>
                            <a href="/#apply">Partnership</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 col-lg-2">
                <div class="single-footer-widget ps-3">
                    <h3>Special Events</h3>

                    <ul class="quick-link">
                        <li>
                            <a href="/carnaval">Carnaval</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 col-lg-2">
                <div class="single-footer-widget ps-3">
                    <h3>Support</h3>

                    <ul class="quick-link">
                        <li>
                            <a href="mailto:contact@nagringa.app">Contact</a>
                        </li>
                        <li>
                            <a href="/legal/privacy-policy">Privacy Policy</a>
                        </li>
                        <li>
                            <a href="/legal/ts-and-cs">Terms & Conditions</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="single-footer-widget">
                    <h3>Address</h3>

                    <ul class="address-info">
                        <li>
                            <i class='bx bxl-whatsapp'></i>
                            <a href="tel:+61411190506">0411 190 506</a>
                        </li>
                        <li>
                            <i class='bx bx-message-square-detail'></i>
                            <a href="mailto:contact@nagringa.app">contact@nagringa.app</a>
                        </li>
                        <li>
                            <i class='bx bx-current-location'></i>
                            Brisbane, QLD Austrália
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="copyright-style-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="copyright-item">
                        <p>© NaGringa PTY LTD</p>
                    </div>
                </div>

            </div>
        </div>
    </div>
</footer>
