<main>

    <section class="ng-card">
        <section class="body">
            <img class="logo" src="https://res.cloudinary.com/nagringaapp/image/upload/v1682487003/logos/logo-app-rounded_256.png">
            <h2>
                NaGringa Card
                <span>by NaGringa</span>
            </h2>
        </section>
    </section>

    <section class="warning">
        If you have not been redirected to the app, download the APP below and click on the link again.
    </section>

    <footer>

        <div class="stores">
            <img src="https://res.cloudinary.com/nagringaapp/image/upload/v1702356562/images/download-google_l5k4bb.png"/>

            <img src="https://res.cloudinary.com/nagringaapp/image/upload/v1702356562/images/download-apple_l894gg.png">
        </div>

        <a href="https://promo.nagringa.app/download">
            Download App
        </a>
    </footer>
</main>
