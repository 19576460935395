import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LinkComponent } from './site/link/link.component';
import { DownloadComponent } from './site/download/download.component';
import { CarnavalComponent } from './site/carnaval/carnaval.component';
import { TsAndCsComponent } from './site/legal/ts-and-cs/ts-and-cs.component';
import { PrivacyPolicyComponent } from './site/legal/privacy-policy/privacy-policy.component';
import { CookieComponent } from './site/legal/cookie/cookie.component';
import { EventComponent } from './site/event/event.component';

@NgModule({
  declarations: [
    AppComponent,
    DownloadComponent,
    CarnavalComponent,
    LinkComponent,
    TsAndCsComponent,
    PrivacyPolicyComponent,
    CookieComponent,
    EventComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
