<div class="one-header-top four-header-top">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-3">
                <div class="header-social">
                    <ul>
                        <li><a target="_blank" href="#"><i class='bx bxl-linkedin'></i></a></li>
                        <li><a target="_blank" href="#"><i class='bx bxl-instagram'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-5">
                <div class="header-partner">
                    <p>Early birds Partners is now available, <a href="https://forms.gle/MVamXvFK55eeJKvp6">Apply Here</a></p>
                </div>
            </div>

        </div>
    </div>
</div>

<!-- <nav class="navbar navbar-expand-lg navbar-light navbar-light-four"> -->
<nav class="navbar navbar-style-two navbar-expand-lg navbar-light  sticky-top">
    <div class="container-fluid">
        <div class="logo">
            <a routerLink="/home-nine">
                <img src="assets/img/logo.png" id="header-logo" class="black-logo" alt="Logo">
            </a>
        </div>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a href="/" class="nav-link">
                        Home
                    </a>
                </li>
                <li class="nav-item">
                    <a href="/#products" class="nav-link">
                        Products
                    </a>
                </li>
                <li class="nav-item">
                    <a href="/#about" class="nav-link">
                        About us
                    </a>
                </li>
                <li class="nav-item">
                    <a href="/#apply" class="nav-link">
                        Parnetship
                    </a>
                </li>
                <li class="nav-item">
                    <a href="/carnaval" class="nav-link">
                        Carnaval
                    </a>
                </li>
                <li class="nav-item">
                    <a href="/download" class="nav-link">
                        Download App
                    </a>
                </li>
            </ul>

            <div class="others-option">
                <div class="d-flex align-items-center">
                    <div class="option-item">
                        <a href="https://forms.gle/MVamXvFK55eeJKvp6" class="sign-up">Become a Partner</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</nav>

<div class="three-banner-area four-banner-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-7">
                        <div class="banner-content">
                            <span>
                                Partnerships
                            </span>
                            <span>
                                The Express of Interest is now opened!
                            </span>
                            <h1>Esquenta NaGringa</h1>
                            <p>As the 2024 Brazilian Carnaval Season approaches, NaGringa extends a special invitation for you to join this Digital Experience, an interactive way to elevate your business exposure through this innovative and interactive solution.</p>
                            <ul>
                                <li>
                                    <a class="cmn-banner-btn" href="#how-it-works">How it works <i class='bx bx-down-arrow-alt'></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<div id="how-it-works" class="three-about-area four-about-area pb-70">
    <div class="container-fluid p-0">
        <div class="row align-items-center m-0">
            <div class="col-lg-6">
                <div class="about-content">
                    <div class="one-section-title three-section-title">
                        <span class="sub-title"># HOW IT WORKS</span>
                        <h2>An interactive and Digital experience that connects People and Businesses.</h2>
                    </div>

                    <div class="about-mission">
                        <ul class="nav nav-pills" id="pills-tab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-default active" id="pills-home-tab" data-bs-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true"><i class='bx bx-bullseye'></i> THE AUDIENCE</a>
                            </li>

                            <li class="nav-item" role="presentation">
                                <a class="nav-default" id="pills-profile-tab" data-bs-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false"><i class='bx bx-show-alt'></i> YOUR BUSINESS</a>
                            </li>
                        </ul>

                        <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                <p>Loads of Brazilian Carnaval parties bashes going Downunder, and guess what? We're spreading the excitement even further this year by inviting people from all nationalities to join in on this digital experience!</p>

                                <ul>
                                    <li>
                                        <div class="about-support">
                                            <span class="emoji">🇧🇷</span>
                                            <h3>The Brazilians</h3>
                                            <p>The key to an unforgettable Carnaval. Dive into the party and soak up that unmistakable Brazilian vibe!</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="about-support">
                                            <span class="emoji">🌏</span>
                                            <h3>Everyone else</h3>
                                            <p>Australias, South Americans, Europeans, Asians, and counting... they all love this Brazilian experience!</p>
                                        </div>
                                    </li>
                                </ul>

                            </div>

                            <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                <p>
                                    Experience the Digital Connection that unites Carnaval attendees before the festivities kick off. Moreover, the platform fosters connections to your business through captivating interactive features.
                                </p>

                                <ul>
                                    <li>
                                        <div class="about-support">
                                            <i class="material-symbols-outlined">language</i>
                                            <h3>Awareness</h3>
                                            <p>Your Business will get exposed to the audience, with an interactive strategy to support organic growth.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="about-support">
                                            <i class="material-symbols-outlined">local_activity</i>
                                            <h3>Prizes</h3>
                                            <p>Attract new customers with exciting Prizes, and reward the loyalty ones who promotes your Business naturally.</p>
                                        </div>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 pr-0">
                <div class="about-img">
                    <img src="assets/img/the-audience.jpg" alt="About">
                    <img src="assets/img/home-four/about-shape.png" alt="About">

                </div>
            </div>
        </div>
    </div>
</div>

<div id="services" class="four-expect-area pt-100 pb-70">
    <div class="container">
        <div class="one-section-title three-section-title">
            <span class="sub-title"># 2023 Festa Junina Season</span>
            <h2>The Correio Elegante was a similar Digital Experience, with the following outcome</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-6">
                <div class="expect-item">
                    <div class="expect-icon">
                        <i class="material-symbols-outlined">
                            diversity_3
                        </i>
                    </div>
                    <h3>1,500+ Participants</h3>
                    <p>In 45 days, the Correio Elegante promotion successfully brought in 1,500 participants, with the majority joining organically through mechanisms that boosted connectivities.</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-6">
                <div class="expect-item active">
                    <div class="expect-icon">
                        <i class="material-symbols-outlined">
                            handshake
                        </i>
                    </div>
                    <h3>1 Major Sponsors, 2 Parties and 10+ Partners</h3>
                    <p>The Correio Elegante was focused only for the Brazilians in Brisbane and Gold Coast. For the 2024 Carnaval, we are making it even bigger</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-6">
                <div class="expect-item">
                    <div class="expect-icon">
                        <i class="material-symbols-outlined">
                            diversity_2
                        </i>
                    </div>
                    <h3>6,000+ Interactions</h3>
                    <p>We had 45% of user's engagement weekly, and 90% of our audience has returned along the Correio Elegante period, exposing even more the partners.</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-6">
                <div class="expect-item">
                    <div class="expect-icon">
                        <i class="material-symbols-outlined">
                            trending_up
                        </i>
                    </div>
                    <h3>Amplified Brand Awareness</h3>
                    <p>The digital promotion has significantly enhanced brand awareness, tapping into a wider new audience for the partners and fostering stronger connections with existing customers.</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="three-join-area four-join-area">
    <div class="">
        <div class="row">
            <div class="col-lg-12">
                <div class="join-item">
                    <h3>We are taking a step further!</h3>
                    <h3>For the Carnaval Season, this promotion welcomes every nationalities and it will also be all across Australia, join this opportunity!</h3>
                    <a class="cmn-banner-btn" href="https://forms.gle/MVamXvFK55eeJKvp6">Apply Here<i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>
        </div>
    </div>
</div>


<footer class="footer-style-area pt-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-sm-6 col-lg-3">
                <div class="single-footer-widget">
                    <div class="footer-logo">
                        <a href="#">
                            <img src="assets/img/logo-white.png" id="footer-logo" alt="Logo">
                        </a>
                        <p>Meaningful Connections, Elevated: Unleashing the Power of Our Interactive Business Platform!</p>
                        <ul class="footer-social">
                            <li><a href="https://www.linkedin.com/company/nagringaapp"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="https://www.instagram.com/nagringa.app"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-2">
                <div class="single-footer-widget ps-3">
                    <h3>Quick Links</h3>

                    <ul class="quick-link">
                        <li>
                            <a href="/#products">Products</a>
                        </li>
                        <li>
                            <a href="/#about">About Us</a>
                        </li>
                        <li>
                            <a href="/#apply">Partnership</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 col-lg-2">
                <div class="single-footer-widget ps-3">
                    <h3>Special Events</h3>

                    <ul class="quick-link">
                        <li>
                            <a href="/carnaval">Carnaval</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 col-lg-2">
                <div class="single-footer-widget ps-3">
                    <h3>Support</h3>

                    <ul class="quick-link">
                        <li>
                            <a href="mailto:contact@nagringa.app">Contact</a>
                        </li>
                        <li>
                            <a href="/legal/privacy-policy">Privacy Policy</a>
                        </li>
                        <li>
                            <a href="/legal/ts-and-cs">Terms & Conditions</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="single-footer-widget">
                    <h3>Address</h3>

                    <ul class="address-info">
                        <li>
                            <i class='bx bxl-whatsapp'></i>
                            <a href="tel:+61411190506">0411 190 506</a>
                        </li>
                        <li>
                            <i class='bx bx-message-square-detail'></i>
                            <a href="mailto:contact@nagringa.app">contact@nagringa.app</a>
                        </li>
                        <li>
                            <i class='bx bx-current-location' ></i>
                            Brisbane, QLD Austrália
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="copyright-style-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="copyright-item">
                        <p>© NaGringa PTY LTD</p>
                    </div>
                </div>

            </div>
        </div>
    </div>
</footer>
