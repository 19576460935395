<main>
  <header>
    <img src="https://res.cloudinary.com/nagringaapp/image/upload/v1690447641/logos/logo-full-white_256_zqey5s.png">
  </header>

  <div class="owner">
    <h3>Thiago Bernardes</h3>
    <p>Founder (CPO/CTO)</p>
  </div>
  <div class="flex-1"></div>

  <section>
    <a href="https://wa.me/+61448087563">
      WhatsApp
    </a>
  </section>

  <section>
    <a href="https://linkedin.com/in/thibernardes">
      LinkedIn
    </a>
  </section>

  <section>
    <a href="https://instagram.com/nagringa.app">
      Follow us on Instagram
    </a>
  </section>
  <div class="flex-1"></div>
  <section>
    <a href="/assets/images/thiago.vcf">
      Add Contact Details
    </a>
  </section>

  <section>
    <a [href]="url" target="_blank" class="download">
      DOWNLOAD APP
    </a>
  </section>

</main>
